



























import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { investText } from '@/utils/pageBottomText'
import PageButtomText from '@/components/pageBottomText/index.vue'
import { PortMudule } from '@/store/modules/portfolio'
import { CommonMudule } from '@/store/modules/common'
import rInvestProfile from './rInvestProfile.vue'
import lAsOverview from './lAsOverview.vue'
import alonelAsOverview from './alonelAsOverview.vue'
import SplitPane from 'vue-splitpane'
import { getPlan, planInvestmentSummary, investStatistics } from '@/api/portfolio'
@Component({
  name: 'asOverview',
  components: {
    moduleHeader,
    PageButtomText,
    SplitPane,
    lAsOverview,
    rInvestProfile,
    alonelAsOverview
  }
})
export default class extends Vue {
  investText = investText
  private isFirst:boolean = true
  aloneNum = '2'
  dataObj:{tbTopData: object[], tableList:object[]} = {
    tableList: [],
    tbTopData: []
  }

  created() {
    this.initFetch()
  }
  resize() {
    // Handle resize
  }
  async initFetch() {
    const res = await getPlan({
      customerId: CommonMudule.customerId
    })
    if (res.statusCode === 0 && res.data) {
      if (res.data.alone) {
        this.aloneNum = '1'
      } else {
        this.aloneNum = '0'
      }
    }
    const p = {
      investmentPlanId: res.data.id
    }
    const res2 = await planInvestmentSummary(p)
    if (res2.statusCode === 0 && res2.data) {
      console.log('res2', res2)
      res2.data.forEach((item:any) => {
        item.subTypeProductActions.forEach((el:any) => {
          el.productActions.forEach((pro:any) => {
            if (pro.product) {
              const temp = CommonMudule.curencyData.find((i:any) => i.value === pro.product.currencyId)
              console.log('temp', temp)
              // @ts-ignore
              pro.itemCurrencyName = temp ? temp.label : ''
            } else {
              pro.itemCurrencyName = '-'
            }
          })
        })
      })
      console.log('res2----', res2)
      this.dataObj.tableList = res2.data
    }
    const res3 = await investStatistics({
      customerId: CommonMudule.customerId
    })
    if (res3.statusCode === 0 && res3.data) {
      console.log('res3', res3)
      this.dataObj.tbTopData.push(res3.data)
    }
    console.log('this.dataObj', this.dataObj)
  }
}
