





















import { Vue, Component, Prop } from 'vue-property-decorator'
import LineChart from '@/components/chart/lineChart.vue'
@Component({
  components: {
    LineChart
  },
})
export default class MultiFunctionLine extends Vue {
  /**
     *  title 标题
     * subTitle 子标题
     * lineTitle
     * data: 数据
     */

    @Prop() title! : string
    @Prop() subTitle! : string
    @Prop() lineTitle! : string
    @Prop() data !: any

    get lineId() {
      return ((Math.random() + 10) * 10000).toFixed(0) + '-lineId'
    }
    get computedOption() {
      const minYArr = []
      for (let i = 0; i < this.data.length; i++) {
        const _i = this.data[i]
        minYArr.push(_i[1])
      }
      const minY = Math.min(...minYArr)
      const option: object = {
        grid: {
          bottom: 20
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          name: '(元)',
          min: minY,
          // axisLine: {
          //   show: false
          // },
          // axisTick: {
          //   show: false
          // },
          splitLine: {
            show: false
          }
        },

        tooltip: {
          trigger: 'axis',
          formatter: (_val: any) => {
            const val = _val[0]
            return `${val.seriesName}</br>${val.value[0]}: ${val.value[1].toFixed(2)}(元)`
          }
        },
        series: [{
          name: this.lineTitle,
          type: 'line',
          symbol: 'circle',
          symbolSize: 6,
          lineStyle: {
            color: '#2179FF'
          },
          itemStyle: {
            color: '#2179FF',
            borderColor: '#fff',
            borderWidth: 1,
          },
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#2179FF'
            }, {
              offset: 1,
              color: '#fff'
            }])

          },
          data: this.data,
        }],

      }
      return option
    }
}
