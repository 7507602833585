






























































import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import lineChart from '@/components/chart/lineChart.vue'
import { SeriesType } from '@/types/kyc'
import variables from '@/styles/_variables.scss'
import { formatRes } from '@/utils/tools'
import {
  fetchEarningLine,
  fetchEarningBar,
} from '@/api/portfolio'
import { cloneDeep } from 'lodash'
import { CommonMudule } from '@/store/modules/common'
import { barOption2 } from '@/store/modules/option'

@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart,
    lineChart
  }
})
export default class extends Vue {
  private isFirst: boolean = true;
  private alone: boolean = true;

  private seriesTime: SeriesType = {
    grid: {
      show: true,
      bottom: 55,
      right: 180,
      left: 180,
      height: 180
    },
    type: 'pie',
    hoverAnimation: true,
    data: []
  };
  private seriesSuggest: SeriesType = {
    grid: {
      bottom: 55,
      right: 180,
      left: 180,
      height: 180
    },
    type: 'pie',
    hoverAnimation: true,
    data: []
  };
   private seriesAdjust: SeriesType = {
     grid: {
       show: true,
       bottom: 55,
       right: 180,
       left: 180,
       height: 180
     },
     type: 'pie',
     hoverAnimation: true,
     data: []
   };

  private optionsEarnings = {}
  private optionsCurrent = {}

  getOptionsEarnings(data1:any, data2:any, data3:any, data4:any) {
    console.log('data1', data1)
    const optionsEarnings = {
      color: [variables.lightcycan],
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: data4,
          axisLabel: {
            interval: 0,
            onZero: false
          },
        }
      ],
      yAxis: {
        offset: 13,
        type: 'value',
        splitLine: {
          show: false,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      series: [
        {
          name: '较好情况',
          areaStyle: {
            color: '#409EFF',
          },
          type: 'line',
          // stack: '总量',
          data: data1
        },
        {
          name: '中值',
          type: 'line',
          // stack: '总量',
          areaStyle: {
            color: '#30B08F'
          },
          showAllSymbol: true, // 标注所有数据点
          data: data2
        },
        {
          name: '较差情况',
          areaStyle: {
            color: '#ffffff',
            opacity: 0.99
          },
          type: 'line',
          // stack: '总量',
          data: data3
        }
      ]
    }
    return optionsEarnings
  }
  created() {
    this.initFetch()
  }

  async initFetch() {
    const p = {
      customerId: CommonMudule.customerId
    }
    const resArr = await Promise.all([
      fetchEarningLine(p),
      fetchEarningBar(p),
    ])
    if (resArr[0].statusCode === 0 && resArr[0].data) {
      console.log(resArr[0].data.dots)
      const res1:any = []
      const res2:any = []
      const res3:any = []
      const res4:any = []
      resArr[0].data.dots.map((item:any) => {
        res1.push(item.betterCondition)
        res2.push(item.normalCondition)
        res3.push(item.worseCondition)
        res4.push(item.year)
      })
      this.optionsEarnings = this.getOptionsEarnings(res1, res2, res3, res4)
      console.log('this.optionsEarnings', this.optionsEarnings)
    }

    if (resArr[0].statusCode === 0 && resArr[1].data) {
      const temp =
      [
        { name: '预计投资总金额',
          value: resArr[1].data.investAmount,
          itemStyle: {
            color: '#2179FF',
          }
        },
        {
          name: '本计划的预计未来价值',
          value: resArr[1].data.cashValue,
          itemStyle: {
            color: '#48CBCB',
          }
        }
      ]
      const option = cloneDeep(barOption2)
      option.series[0].data = temp
      option.xAxis[0].data = ['预计投资总金额', '本计划的预计未来价值']
      this.optionsCurrent = option
    }
  }
}
