
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PageButtomText extends Vue {
    @Prop() textObj !: object
}
