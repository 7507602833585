





import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import echarts, { EChartOption } from 'echarts'
import { mixins } from 'vue-class-component'
import ResizeMixins from './resize-mixins'
import { SeriesType } from '@/types/kyc'

@Component({
  name: 'pieChart'
})
export default class extends mixins(ResizeMixins) {
  @Prop({ default: 'chartId' }) private id!: string
  @Prop({ default: '200px' }) private width!: string
  @Prop({ default: '200px' }) private height!: string
  @Prop({ default: () => ['#48A2FF', '#48CBCB', '#56CC76', '#FBD44E'], required: false }) private color!: string[]
  @Prop({ required: true }) private series!: SeriesType[]

  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  }

  @Watch('series', { deep: true })
  onFilterTextChange(value: any) {
    this.setOpt()
  }

  init() {
    this.chart = echarts.init(document.getElementById(this.id) as HTMLDivElement, '', {
      width: this.width,
      height: this.height
    })
    this.setOpt()
  }

  setOpt() {
    if (this.chart) {
      this.chart.setOption({
        grid: {
          bottom: 55,
          left: 120,
          height: 180,
          right: 120
        },
        color: this.color,
        series: this.series,
        tooltip: {
          formatter: '{b} : {d}%'
        },
      } as EChartOption<EChartOption.SeriesLine>)
    }
  }
}
