/**
 * 这个工具方法主要存放页面底部文字提示
 *   !! title 默认 风险提示
 *      content 内容文本
 * analys 财富分析建议相关
 * investText 投资组合规划
 * familyBaseText 家庭基础保障规划
 * pension  养老退休规划
 * child 子女教育规划
 */

const analysText = {
  content: ['以上内容仅供参考，对客户的适用性取决于多项因素，其中包括客户所提供信息的真实有效性，任何由客户提供给我司的信息，客户确认其真实有效，并对因该信息产生的后果承担全部责任。我司对以上内容和任何报告文件不收取费用，亦不对此承担任何责任。',
    '请客户注意，客户在不同的时间使用系统或阅读本文件会产生不同的理解或结果，并且此种理解或结果会随时间、有关情况的不同而不同，并非永久不变。']
}

const investText = {
  content: [
    '以上内容仅供参考，对客户的适用性取决于多项因素，我司对以上内容和任何报告文件不收取费用，亦不对此承担任何责任。',
    '在这份报告里所使用的价值、计算和图表只供说明用途，仅作参考。它们没有考虑到销售费用、酬金、税金等情况，亦没有说明任何真实产品、投资计划的表现，或者是市场状况。',
    '相关风险概况和投资组合模式仅作参考，不能被理解为财务咨询。我司资产配置模型的年化预期回报率和年化可能损失，包括目标规划预计目标价值，都仅作为说明和参考用途，我司并不作任何保证。客户必须行使自己的判断能力，不应该以建议或图表为准。此外，客户必须针对自己特定的投资需求，向专业人士寻求专业咨询。',
    '这份报告内容主要作为说明用途，并非想要试图要约，或征求要约出售或购买/认购任何产品，包括投资产品。在任何情况下，它也不能被视为是我司给予的咨询。我司将不会对客户所选择信赖的资料包括这里面所载的资料负责。因此，客户必须使用自己的独立判断能力，如果有任何疑问，您必须另外寻求独立的咨询。',
    '请客户注意，客户在就本文件或相关系统所有具体产品实例的选择或确认，均是客户自行判断后作出的，并不基于任何人或机构的推荐或建议。',
    '请客户注意，任何具体产品是否可以由客户实际购买，除了要签署有关的产品文件之外，还需要依据产品适合度评估的结果来确定。',
    '请客户注意，本文件中的任何数据或信息（客户提供的数据或信息除外）均仅仅作为一种假设的情况或可能性的情况，并非任何确定的预测或确认。',
    '请客户注意，客户在不同的时间使用系统或阅读本文件会产生不同的理解或结果，并且此种理解或结果会随时间、有关情况的不同而不同，并非永久不变。',
    '如果客户决定购买/认购任何投资产品，请注意，任何投资产品都有风险，有关投资产品的过去表现，并不能反映它的未来表现，其价格可有涨有跌，客户可能无法取回客户原本的投资金额。因此，在投资之前，建议投资者必须详细阅读以及了解产品说明书内容，考虑所涉及的风险。我司不会对任何投资者的任何亏损，承担任何赔偿责任。投资者可能会因为投资产品的表现而蒙受亏损。有关投资产品未必适合所有人，如果有疑问，投资者应该寻求独立专业咨询。'
  ]
}

const familyBaseText = {
  content: [
    '以上内容仅供参考，对客户的适用性取决于多项因素，其中包括客户所提供信息的真实有效性，任何由客户提供给我司的信息，客户确认其真实有效，并对因该信息产生的后果承担全部责任。我司对以上内容和任何报告文件不收取费用，亦不对此承担任何责任。',
    ' 在这份报告里所使用的价值、计算和图表只供说明用途，仅作参考。它们没有考虑到销售费用、酬金、税金等情况，亦没有说明任何真实产品、投资计划的表现，或者是市场状况。',
    ' 这份报告内容主要作为说明用途，并非想要试图要约，或征求要约出售或购买/认购任何金融产品。在任何情况下，它也不能被视为是我司给予的咨询。我司将不会对客户所选择信赖的资料包括这里面所载的资料负责。因此，客户必须使用自己的独立判断能力，如果有任何疑问，您必须另外寻求独立的咨询。',
    ' 请客户注意，客户在就本文件或相关系统所有具体产品实例的选择或确认，均是客户自行判断后作出的，并不基于任何人或机构的推荐或建议。',
    ' 请客户注意，本文件中的任何数据或信息（客户提供的数据或信息除外）均仅仅作为一种假设的情况或可能性的情况，并非任何确定的预测或确认。',
    ' 请客户注意，客户在不同的时间使用系统或阅读本文件会产生不同的理解或结果，并且此种理解或结果会随时间、有关情况的不同而不同，并非永久不变。',
    ' 如果客户决定购买/认购任何金融产品，应该寻求独立专业咨询。',
  ]
}

const persionText = {
  content: [
    '以上内容仅供参考，对客户的适用性取决于多项因素，其中包括客户所提供信息的真实有效性，任何由客户提供给我司的信息，客户确认其真实有效，并对因该信息产生的后果承担全部责任。我司对以上内容和任何报告文件不收取费用，亦不对此承担任何责任。',
    ' 在这份报告里所使用的价值、计算和图表只供说明用途，仅作参考。它们没有考虑到销售费用、酬金、税金等情况，亦没有说明任何真实产品、投资计划的表现，或者是市场状况。',
    ' 相关配置组合模式仅作参考，不能被理解为财务咨询。相关目标规划预计目标价值，都仅作为说明和参考用途，我司并不作任何保证。客户必须行使自己的判断能力，不应该以建议或图表为准。此外，客户必须针对自己特定的投资需求，向专业人士寻求专业咨询。',
    ' 这份报告内容主要作为说明用途，并非想要试图要约，或征求要约出售或购买/认购任何金融产品。在任何情况下，它也不能被视为是我司给予的咨询。我司将不会对客户所选择信赖的资料包括这里面所载的资料负责。因此，客户必须使用自己的独立判断能力，如果有任何疑问，您必须另外寻求独立的咨询。',
    ' 请客户注意，客户在就本文件或相关系统所有具体产品实例的选择或确认，均是客户自行判断后作出的，并不基于任何人或机构的推荐或建议。',
    ' 请客户注意，任何具体产品是否可以由客户实际购买，除了要签署有关的产品文件之外，还需要依据产品适合度评估的结果来确定。',
    ' 请客户注意，本文件中的任何数据或信息（客户提供的数据或信息除外）均仅仅作为一种假设的情况或可能性的情况，并非任何确定的预测或确认。',
    ' 请客户注意，客户在不同的时间使用系统或阅读本文件会产生不同的理解或结果，并且此种理解或结果会随时间、有关情况的不同而不同，并非永久不变。',
    ' 如果客户决定购买/认购任何投资产品，请注意，任何投资产品都有风险，有关投资产品的过去表现，并不能反映它的未来表现，其价格可有涨有跌，客户可能无法取回客户原本的投资金额。因此，在投资之前，建议投资者必须详细阅读以及了解产品说明书内容，考虑所涉及的风险。我司不会对任何投资者的任何亏损，承担任何赔偿责任。投资者可能会因为投资产品的表现而蒙受亏损。有关投资产品未必适合所有人，如果有疑问，投资者应该寻求独立专业咨询。',
  ]
}

const childText = {
  content: [
    '以上内容仅供参考，对客户的适用性取决于多项因素，其中包括客户所提供信息的真实有效性，任何由客户提供给我司的信息，客户确认其真实有效，并对因该信息产生的后果承担全部责任。我司对以上内容和任何报告文件不收取费用，亦不对此承担任何责任。',
    ' 在这份报告里所使用的价值、计算和图表只供说明用途，仅作参考。它们没有考虑到销售费用、酬金、税金等情况，亦没有说明任何真实产品、投资计划的表现，或者是市场状况。',
    ' 相关配置组合模式仅作参考，不能被理解为财务咨询。相关目标规划预计目标价值，都仅作为说明和参考用途，我司并不作任何保证。客户必须行使自己的判断能力，不应该以建议或图表为准。此外，客户必须针对自己特定的投资需求，向专业人士寻求专业咨询。',
    ' 这份报告内容主要作为说明用途，并非想要试图要约，或征求要约出售或购买/认购任何金融产品。在任何情况下，它也不能被视为是我司给予的咨询。我司将不会对客户所选择信赖的资料包括这里面所载的资料负责。因此，客户必须使用自己的独立判断能力，如果有任何疑问，您必须另外寻求独立的咨询。',
    ' 请客户注意，客户在就本文件或相关系统所有具体产品实例的选择或确认，均是客户自行判断后作出的，并不基于任何人或机构的推荐或建议。',
    ' 请客户注意，任何具体产品是否可以由客户实际购买，除了要签署有关的产品文件之外，还需要依据产品适合度评估的结果来确定。',
    ' 请客户注意，本文件中的任何数据或信息（客户提供的数据或信息除外）均仅仅作为一种假设的情况或可能性的情况，并非任何确定的预测或确认。',
    ' 请客户注意，客户在不同的时间使用系统或阅读本文件会产生不同的理解或结果，并且此种理解或结果会随时间、有关情况的不同而不同，并非永久不变。',
    ' 如果客户决定购买/认购任何投资产品，请注意，任何投资产品都有风险，有关投资产品的过去表现，并不能反映它的未来表现，其价格可有涨有跌，客户可能无法取回客户原本的投资金额。因此，在投资之前，建议投资者必须详细阅读以及了解产品说明书内容，考虑所涉及的风险。我司不会对任何投资者的任何亏损，承担任何赔偿责任。投资者可能会因为投资产品的表现而蒙受亏损。有关投资产品未必适合所有人，如果有疑问，投资者应该寻求独立专业咨询。',
  ]
}

export {
  analysText,
  investText,
  familyBaseText,
  persionText,
  childText
}
