












































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { investStatistics } from '@/api/portfolio'
import { comdify } from '@/utils/validate'

@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader
  }
})
export default class extends Vue {
  comdify = comdify
  @Prop({ required: true }) private dataObj!: object
  private obj = {
    tbTopData: [],
    tableList: []
  }

  @Watch('dataObj', { deep: true })
  private watchDataObj(v: any) {
    this.$nextTick(() => {
      this.obj = v
    })
  }
}
