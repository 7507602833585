import request from '@/utils/request'
import { CustomInfo, PlanIdParams } from '@/types/common'
import { InvestPlan, ArticleType, RiskTypeParam, InvestParam, InvestPlanAlone } from '@/types/portfolio'

/**
 * 删除投资规划
 */
export const deletePlan = (data:any) =>
  request({
    url: `/blackcat/investment/plan/delete?${data}`,
    method: 'post',
    // data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    // }
  })
/**
 * 不同风险等级type配比
 */
export const fetchRiskType = (params: RiskTypeParam) =>
  request({
    url: '/blackcat/allocation/suggest/list/type',
    method: 'get',
    params
  })

/**
 * 获取文章列表
 */
export const fetchArticleList = (params: ArticleType) =>
  request({
    url: '/blackcat/article/list',
    method: 'get',
    params
  })
/**
 *  单独类型收益
 */
export const fetchEarnList = () =>
  request({
    url: '/blackcat/prospective/earnings/list',
    method: 'get',
  })

/**
 *  现有资产总览
 */
export const fetchNowAs = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/asset/summary',
    method: 'get',
    params
  })

/**
 *  现有资产比例饼图
 */
export const fetchNowChart = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/origin/percentage',
    method: 'get',
    params
  })
/**
 *  现有资产比例饼图
 */
export const fetchSuggestChart = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/suggest/percentage',
    method: 'get',
    params
  })
/**
 *  现有资产收益预测表格
 */
export const fetchProfitTable = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/earnings/forecast',
    method: 'get',
    params
  })
/**
 *  调整投资后比例饼图
 */
export const fetchCurrentChart = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/current/percentage',
    method: 'get',
    params
  })

/**
 *  12年后本投资木星测算累计收益率
 */
export const fetchEarningLine = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/earnings/forecast/chart',
    method: 'get',
    params
  })

/**
 *  调整投资后比例饼图
 */
export const fetchEarningBar = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/earnings/column/diagram',
    method: 'get',
    params
  })

/**
*  现有资产产品分类
*/
export const fetchTransactions = (params: any) =>
  request({
    url: '/blackcat/investment/plan/transactions',
    method: 'get',
    params
  })
/**
*  现有资产产品列表
*/
export const fetchProductList = (params: any) =>
  request({
    url: '/blackcat/product/page',
    loading: false,
    method: 'get',
    params
  })

/**
 * 建议配置模型历史收益测算
 */
export const getHistoryEarn = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/prospective/earnings',
    method: 'get',
    params
  })
/**
 * 查询投资规划
 */
export const getPlan = (params: CustomInfo) =>
  request({
    url: '/blackcat/investment/plan/find',
    method: 'get',
    params
  })
/**
 * 保存投资规划
 */
export const savePlan = (data: InvestPlan | InvestPlanAlone) =>
  request({
    url: '/blackcat/investment/plan/save',
    method: 'post',
    data
  })
/**
 * 保存组合规划第二页
 */
export const saveSecondPlan = (data: any) =>
  request({
    url: '/blackcat/investment/plan/actions/save',
    method: 'post',
    data
  })
/**
 * 组合规划第二页点击分类弹框信息
 */
export const SecondDetail = (params: any) =>
  request({
    url: '/blackcat/investment/plan/type/asset/info',
    method: 'get',
    params
  })
  /**
 * 获取兑换人民币的汇率
 */
export const changeRmbRate = (params: any) =>
  request({
    url: '/blackcat/currency/exchange/cny',
    method: 'get',
    params
  })

/**
 * 汇率计算
 * currencyId1 / currencyId2
 * 默认 美元 / 人民币
 */
export const changeRate = (currencyId1: string = 'currency-id-0002', currencyId2:string = 'currency-id-0001') =>
  request({
    url: '/blackcat/currency/exchange',
    method: 'get',
    params: {
      currencyId1,
      currencyId2
    }
  })
/**
 * 投资概览表格
 */
export const planInvestmentSummary = (params: PlanIdParams) =>
  request({
    url: '/blackcat/investment/plan/investment/summary',
    method: 'get',
    params
  })
  /**
 * 删除投资组合
 */
export const deleteSecondPlan = (params: {id: string}) =>
  request({
    url: '/blackcat/product/action/delete',
    method: 'POST',
    params
  })
/**
 * 风险类型查询
 */
export const riskTypeFind = (params: any) =>
  request({
    url: '/blackcat/customer/find',
    method: 'POST',
    params
  })
/**
 *风险类型保存
 */
export const riskTypeSave = (params: any) =>
  request({
    url: '/blackcat/customer/risk/update',
    method: 'POST',
    params
  })

/**
 * 本次投资比例饼图
 */
export const timeInvest = (params: any) =>
  request({
    url: '/blackcat/investment/plan/action/percentage',
    method: 'get',
    params
  })
/**
 * page3 投资统计
 */
export const investStatistics = (params: any) =>
  request({
    url: '/blackcat/investment/plan/statistics',
    method: 'get',
    params
  })
  /**
 *调整规划删除操作
 */
export const deleGroup = (params: any) =>
  request({
    url: '/blackcat/investment/plan/transaction/delete',
    method: 'POST',
    params
  })

/**
 * 数据回测折线图图Dialog
 *
 */
export const available = () =>
  request({
    url: '/blackcat/indicator/list/available',
    method: 'get'
  })
/**
 * 回测数据
 *
 */
export const backTest = (idx:string, params: any) =>
  request({
    url: '/blackcat/investment/plan/back/test?' + idx,
    method: 'get',
    params
  })

/**
 * 回测数据 资产配置单独 第三页折线图
 *
 */
export const backTest3 = (params: any) =>
  request({
    url: '/blackcat/investment/plan/product/back/test',
    method: 'get',
    params
  })

/**
   * 明星产品一键投资
   *
   */
export const starProduct = (params: any) =>
  request({
    url: '/blackcat/investment/plan/star/product/action',
    method: 'get',
    params
  })
