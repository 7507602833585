





import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import echarts, { EChartOption } from 'echarts'
import { mixins } from 'vue-class-component'
import { seriesPieColor } from '@/store/modules/option'
import ResizeMixins from './resize-mixins'
import { SeriesType } from '@/types/kyc'

@Component({
  name: 'pieChart'
})
export default class extends mixins(ResizeMixins) {
  @Prop({ default: 'chartId' }) private id!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '200px' }) private height!: string
  @Prop({ default: () => seriesPieColor, required: false }) private color!: string[]
  @Prop({ required: true }) private option!: object

  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  }

  @Watch('option', { deep: true })
  onFilterTextChange(value: any) {
    this.setOpt()
  }

  init() {
    this.chart = echarts.init(document.getElementById(this.id) as HTMLDivElement, '', {
      width: this.width,
      height: this.height
    })
    this.setOpt()
  }

  setOpt() {
    if (this.chart) {
      this.chart.setOption(this.option as EChartOption<EChartOption.SeriesLine>, true)
    }
  }
}
